.home_block {
  @include span-columns(12);
  @include omega(1);
  &.office {
    background: url('../images/office365.png') center 25px no-repeat $koamaru;
  }
  &.ssl {
    background: url('../images/ssl.png') center 20px no-repeat $wlx-main;
  }
  &.webhosting {
    background: url('../images/webhosting.png') center 20px no-repeat $koamaru;
    margin-bottom: 5px;
  }
  &.voip {
    background: url('../images/voip.png') center 20px no-repeat $koamaru;
    margin-bottom: 5px;
  }
  &.cloudservers {
    background: url('../images/cloud.png') center 25px no-repeat $wlx-main;
    margin-bottom: 5px;
  }
  @include break1 {
    @include omega-reset(1);
    @include span-columns(6);
    @include omega(2);
    &.office {
      //background: url('../images/office365.png') center 25px no-repeat $darkgray;
      background: url('../images/office365.png') center 25px no-repeat $koamaru;
    }
    &.ssl {
      background: url('../images/ssl.png') center 20px no-repeat $wlx-main;
    }
    &.webhosting {
      background: url('../images/webhosting.png') center 20px no-repeat $wlx-main;
      margin-bottom: 15px;
    }
    &.voip {
      background: url('../images/voip.png') center 20px no-repeat $wlx-main;
      margin-bottom: 15px;
    }
    &.cloudservers {
      background: url('../images/cloud.png') center 25px no-repeat $koamaru;
      margin-bottom: 15px;
    }
  }
  text-align: center;
  color: $white;
  padding: 70px 20px 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  h2 {
    color: $white;
    margin: 20px 0;
    font-size: 18px;
  }
}

.home_over_ons {
  @include full-bg($lightblue);
  text-align: center;
  color: $koamaru;
  font-size: 14px;
  padding: 5px 0 0;
  h2 {
    color: $wlx-main;
    margin: 20px 0;
    font-size: 28px;
    font-weight: lighter;
  }
  a.button-over-ons {
    color: $koamaru;
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid $koamaru;
    background: lighten($lightblue, 10%);
    text-decoration: none;
    &:hover {
      background: $koamaru;
      color: $white;
    }
  }

}

a.button_home {
  display: inline-block;
  width: 50%;
  min-width: 200px;
  border: 1px solid $white;
  margin: 5px;
  padding: 10px;
  color: $white;
  background: rgba(255, 255, 255, 0.1);
  text-decoration: none !important;
  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
}