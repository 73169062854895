.view-home-hosting-pakketten,
.view-linux-hosting,
.view-windows-hosting {
  .views-row {
    @include span-columns(12);
    @include omega(1n);
    margin-right: 2px !important;

    @include break1 {
      @include omega-reset(1n);
      @include span-columns(6);
      @include omega(2n);
    }
    @include break2 {
      @include omega-reset(2n);
      @include span-columns(3);
      @include omega(4n);
    }
    .odd {
      background: $lightestgray;
      width: 100% !important;
    }
    //background: $lightestgray;
    padding: 0px;
    //border: 1px solid $lightergray;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    .views-field {
      padding: 10px 0;
      span {
        display: inline-block;
        width: 60%;
        text-align: left;
        margin: 0 20px 0 0;
      }
      div {
        display: inline-block;
        width: 20%;
      }
      &.views-field-title {
        display: block;
        padding: 20px 0px;
        background: $wlx-main;
        color: $white;
        font-size: 24px;
        font-weight: lighter;
        border-top: 2px solid $white;
        position: relative;
        span {
          width: 100%;
          text-align: center;
        }
      }
    }
    
    .views-field-commerce-price {
      .field-content {
        width: 100%;
        color: $mediumgray;
      }
    }
    .views-field-field-price-month {
      background: $veeam;
      position: relative;
      margin-top: 25px;
      //border: 4px solid #c2e1f5;
      .field-content {
        color: $white;
        font-size: 2.4rem;
        margin: 10px 0;
        width: 100%;
      }
      &:before {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: $veeam;
        border-width: 15px;
        margin-left: -15px;
        
      }
    }
    div.views-field-add-to-cart-form {
      span {
        margin: 0;
      }
    }
    form.commerce-add-to-cart {
      text-align: center;
      margin: 10px 0;
      input[type='submit'] {
        //font-size: 1.6rem;
        //padding: 20px;
        margin: 0 0 0 -50px;
      }
    }
    .vink_off {
      width: 15px;
      height: 15px;
      background: url('../images/cross_small.png') top center no-repeat;
      text-indent: -9999999px;
    }
    .vink_on {
      width: 15px;
      height: 15px;
      background: url('../images/check_small.png') top center no-repeat;
      text-indent: -9999999px;
    }
  }
}