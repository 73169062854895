.page-domeinnaam-bestellen {
  .sidebar-first {
    display: none;
    @include break1 {
      display: block;
    }
  }  
}

.domeinnaam_container {
  clear: both;
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid $lightergray;
  position: relative;
  min-height: 120px;
  &.nietbeschikbaar {
    //min-height: 180px;
    //.group-nameservers {
    //  bottom: 55px;
    //  left: 0;
    //}
  }
  &.odd {
    /* background: $lightestgray; */
  }
  .domeinnaam_name {
    &.beschikbaar {
      color: $green;
    }
    &.nietbeschikbaar {
      color: $bittersweet;
    }
    float: left;
    width: 25%;
    min-width: 200px;
    margin: 0 10px 10px 0;
    .sld {
      font-size: 17px;
    }
    .tld {
      font-size: 21px;
      font-weight: 600;
    }
  }
  .domeinnaam_price {
    float: left;
    width: 15%;
    margin: 0 10px 0 0;
    font-size: 18px;
    font-weight: 600;
   
    color: $wlx-main;
  }
  .domeinnaam_status {
    clear: both;
    color: $mediumgray;
    &.nietbeschikbaar {
      color: $bittersweet;
      margin: 0;
      @include break2 {
        margin: -46px 0 0 130px;
      }
    }
  }
  .domeinnaam_atc {
    width: 100%;
    .form-submit {
      font-size: 14px;
      padding: 15px 20px;
    }
    form.verhuizen {
      input[type=submit] {
        background: $bittersweet;
        //position: absolute;
        //top: 20px;
        //right: 10px;
        &:hover {
          background: lighten($bittersweet, 10%);
        }
      }
    }
    .field-name-field-nameserver-1,
    .field-name-field-nameserver-2,
    .field-name-field-nameserver-3,
    .field-name-field-nameserver-4 {
      label {
        display: none;
      }
      input {
        margin: 0 0 10px;
      }
    }
  }
  .form-item-line-item-fields-field-transfercode-und-0-value {
    input {
      //position: absolute;
      //left: 0px;
      //bottom: 0px;
      margin: 10px 0;
    }
  }
  //.group-nameservers {
  //  position: absolute;
  //  bottom: 10px;
  //  left: 0;
  //  background: $lightestgray;
  //  border: 1px solid $lightergray;
  //  border: 1px solid $lightergray;
  //  padding: 20px;
  //  z-index: 9;
  //  &.collapsed {
  //    border: 0px;
  //    background: $white;
  //    padding: 20px 20px 10px 0;
  //    text-align: left;
  //    span.fieldset-legend  a {
  //      &:after {
  //        content: ' ';
  //      }
  //    }
  //  }
  //  legend {
  //    padding: 0;
  //    font-weight: normal;
  //    background: $white;
  //    text-align: left;
  //    a {
  //      &:hover {
  //        color: $lightgray;
  //      }
  //    }
  //    span.fieldset-legend {
  //      a {
  //        display: inline-block;
  //        padding: 5px 10px 0;
  //        &:after {
  //          content: ' -  X ';
  //        }
  //      }
  //    }
  //  }
  //  .field-name-field-nameserver-1,
  //  .field-name-field-nameserver-2 {
  //    margin: 10px 0;
  //    label {
  //      display: none;
  //    }
  //  }
  //  .fieldset-description {
  //    text-align: left;
  //  }
  //}
}
