.view-ssl-certificaten {
  .grippie {
    display: none;
  }
  .views-row {
    @include span-columns(12);
    @include omega(1n);
    margin-right: 2px !important;

    @include break1 {
      @include omega-reset(1n);
      @include span-columns(6);
      @include omega(2n);
    }
    @include break2 {
      @include omega-reset(2n);
      @include span-columns(4);
      @include omega(3n);
    }
    position: relative;
    //background: $lightestgray;
    //border: 1px solid $lightergray;
    text-align: center;
    padding: 70px 20px 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    .views-field-title {
      display: block;
      padding: 20px 0px;
      background: $wlx-main;
      color: $white;
      font-size: 24px;
      font-weight: lighter;
      border-top: 2px solid $white;
      position: relative;
      span {
        width: 100%;
        text-align: center;
        display: block;
      }
    }
    .views-field-field-description {
      margin: 10px 0;
      min-height: 50px;
      display: block;
      color: $wlx-main;
    }
    .views-field-commerce-price {
      background: $veeam;
      position: relative;
      margin-top: 25px;
      //border: 4px solid #c2e1f5;
      .field-content {
        color: $white;
        font-size: 2.4rem;
        margin: 10px 0;
        width: 100%;
        display: block;
        padding: 10px 0;
      }
      &:before {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: $veeam;
        border-width: 15px;
        margin-left: -15px;
        
      }
    }
    .field-name-field-csr,
    .field-name-field-server-type,
    .field-name-field-bevestigings-email {
      label {
        display: none;
      }
      select {
        margin: 0 0 10px;
        color: $lightgray;
      }
      textarea {
        margin: 0 0 10px;
      }
      small {
        display: block;
        margin: 20px 0;
        font-size: 13px;
        color: $wlx-main;
        line-height: 1.5;
      }
    }
    .field-name-field-bevestiging-email {
      margin: 10px 0 20px;
      label {
        width: 100%;
      }
      .form-type-select {
        width: 40%;
        float:left;
      }
      .bevestiging_email_suffix {
        width: 50%;
        float:left;
        min-height: 50px;
        margin: 35px 0 0 10px;
        text-align: left;
      }
    }
    form.commerce-add-to-cart {
      text-align: center;
      margin: 0 0 10px;
      input[type='submit'] {
        @include button;
      }
    }
    .vink_off {
      width: 15px;
      height: 15px;
      background: url('../images/cross_small.png') top center no-repeat;
      text-indent: -9999999px;
    }
    .vink_on {
      width: 15px;
      height: 15px;
      background: url('../images/check_small.png') top center no-repeat;
      text-indent: -9999999px;
    }
    input[type=submit] {
      clear: both;
    }
  }
}