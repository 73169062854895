table.responsive {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  @extend %m-p-reset;
  border: none;
  thead {
    display: none;
    background: $lightestgray;
    color: $darkgray;
    border-bottom: 1px solid $lightergray;
    text-align: left;
    th {
      padding: 10px;
      border-right: none;
      &:last-child {
        border-right-color: $lightgray;
      }
    }
  }
  tr {
    display: block;
    td {
      display: block;
      text-align: right;
      padding: 10px;
      &:before {
        content: attr(data-title)' ';
        font-weight: bold;
        float: left;
      }
      a {
        color: $black;
        &:hover {
          color: $wlx-main;
          text-decoration: underline;
        }
      }
    }
    &.odd {
      background: #EEE;
    }
    &.even {
      background: #DDD;
    }
  }
  @include break1 {
    thead {
      display: table-row-group;
    }
    tr {
      border: 0;
      display: table-row;
      td {
        display: table-cell;
        border: none;
        text-align: left;
        &:before {
          content: '';
        }
      }
    }
  }
  &.commerce-price-formatted-components {
    margin: 20px 0 20px auto;
  }
}

.field-name-commerce-order-total .commerce-price-formatted-components tr.component-type-commerce-price-formatted-amount {
  background: #EEEEEE;
}

.view-commerce-line-item-table {
  table.responsive {
    tr {
      border-bottom: 1px solid $lightergray;
      td {
        padding: 20px 0 20px 10px;
      }
      &.odd {
        background: $white;
      }
    }
  }
}