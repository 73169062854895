.call-back-block {
  position: fixed;
  bottom: 30px;
  right: 10px;
  z-index: 999999999999999999999999;
  h2 {
    padding: 10px 0 0 60px;
    background: url('../images/phone2.png') 10px 0px no-repeat;
    height: 60px;
    font-size: 0;
    z-index: 1;
    position: relative;
    &:hover {
      cursor: pointer;
    }
  }
  article.node-webform {
    display: none;
    border: 1px solid $wlx-main;
    width: 300px;
    height: auto;
    background: $white;
    padding: 10px;
    color: $koamaru;
    margin-top: -25px;
    .webform-component--uw-telefoonnummer {
      margin: 5px 0 10px;
      label {
        display: none;
      }
    }
    .form-submit {
      width: 100%;
    }
    .webform-confirmation {
      font-weight: bold;
      color: $wlx-main;
    }
    .links a.ajax-processed {
      display: none;
    }
    .error {
      background-image: none;
      padding: 4px;
    }
  }
  .messages.status.webform-confirmation {
    display: none;
  }
}