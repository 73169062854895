#webform-ajax-wrapper-94, //old tool
.commerce-cart-add-to-cart-form-1282 { // VPS TOOL
  fieldset {
    margin: 0 0 10px;
    min-height: auto;
    .form-item {
      margin: 10px 0;
    }
    &.group-vps-opties {
      .form-item {
        margin: 10px 0;
      }
    }
    @include break1 {
      //min-height: 600px;
    }
    label,
    .label {
      display: inline-block;
      min-width: 220px;
      color: $lightgray;
      &.required {
        &:after {
          content: ' ';
        }
      }
    }
    .label {
      margin-right: 5px;
    }
    .field-type-markup {
      p {
        display: inline-block;
        min-width: 200px;
      }
    }
    select {
      width: auto;
      min-width: 130px;
      //border: 0;
    }
    div.form-radios {
      width: auto;
      display: inline-block;
    }
    .form-type-radio {
      margin: 0 20px 0 0;
      width: auto;
      display: inline-block;
      label {
        width: auto;
        min-width: inherit;
        margin: 0 20px 0 0;
        &:after {
          content: ' ';
        }
      }
    }
    .webform-component-radios {
      margin: 10px 0;
    }
    
    &.webform-component--left,
    &.webform-component--right,
    &.group-vps-opties,
    &.group-vps-eigenschappen {
      @include span-columns(12);
      @include omega(1n);
      @include break1 {
        @include omega-reset(1n);
        @include span-columns(6);
        @include omega(2n);
      }
      select {
        min-width: 150px;
      }
    }
    .group-vps-monitoring,
    .group-monitoring-updates, 
    .group-vps-security,
    .group-vps-management,
    .group-vps-maintenance {
      div {
        span {
          display: block;
          width: 100%;
          padding: 5px 10px;
          background: #EDEDED;
        }
      }
    }
  }
  .webform-component--totaal,
  .field-name-field-vps-totaal {
    background: $lightestgray;
    padding: 20px 20px 10px;
    //clear: both;
    float:left;
    @include span-columns(12);
    @include omega(1n);
    @include break1 {
      @include omega-reset(1n);
      @include span-columns(6);
      @include omega(2n);
      clear: none !important;
    }
    margin: 0 0 10px;
    .vps_total {
      .label {
        color: $lightgray;
        font-size: 25px;
        margin: 0 20px 0 0;
        display: inline-block;
        width: auto;
      }
      .value {
        font-size: 25px;
        color: $wlx-main;
        margin: 0 10px 0 0;
        display: inline-block;
        width: auto;
      }
    }
    .description {
      color: $lightgray;
      font-size: 11px;
      display: block;
      margin: 15px 0  0;
    }
  }
}

form.commerce-cart-add-to-cart-form-1282 {
  input[type=submit] {
    float: right;
    margin: -105px 15px 0 0;
    @include break1 {
      margin: -105px 15px 0 0;    
    }
  }
}

label {
  margin-right:10px;
}