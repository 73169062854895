// -- PLACEHOLDERS -- //

// -- USAGE: @extend %m-p-reset;
// -- NOTE: Placeholders don't take params

// *
// * M-P-reset extendable: remove all padding and margin on element
// *
%m-p-reset {
  margin: 0;
  padding: 0;
}

// *
// * Kill-transitions extendable: kill all transitions used on an element
// *
%kill-transitions {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
}

// *
// * Image extendable: set image width to 100% and height to auto
// *
%image {
  width: 100%;
  height: auto;
}

// *
// * Clearfix extendable
// *
%clearfix {
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}
