.node-type-webform {
  .contact-adresgegevens {
    background: $lightestgray;
    width: 95%;
    padding: 20px;
  }
  
  label {
    margin: 0 0 10px;
    color: $lightgray;
  }
  
  input,
  select,
  textarea {
    margin: 0 0 10px;
  }
}