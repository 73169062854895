.entity-commerce-order {
  .field-name-commerce-customer-billing,
  .field-name-commerce-customer-administratie {
    background: $lightestgray;
    border: 1px solid $lightergray;
    padding: 20px;
    margin: 0 0 10px;
    label {
      font-size: 20px;
      font-weight: normal;
      color: $wlx-main;
    }
    .field-name-field-copy-address {
      display: none;
    }
    .field-name-field-btw-nummer {
      label {
        display: none;
      }
    }
  }
}