footer {
  @include full-bg($darkgray);
  color: $white;
  .floormat {
    h2 {
      color: $wlx-main;
    }
    a {
      color: $white;
      &:hover {
        color: $lightgray;
      }
    }
  }
  .floormat-1,
  .floormat-2,
  .floormat-3 {
    @include span-columns(12);
    @include omega(1n);
    padding: 20px 10px;
    @include break1 {
      @include omega-reset(1n);
      @include span-columns(4);
      @include omega(3n);
    }
  }
  .floormat-2 {
    background: lighten($darkgray, 5%);
    @include break0 {
      background: $darkgray;
    }
  }

  .floormat-1 {
    img {
      width: 20% !important;
      max-width: 65px;
      height: auto !important;
      margin: 0 10px 0 0;
      &.bancontact {
        margin: 0 15px 0 0;
      }
      &.maestro {
        margin: 0 0 -10px;
      }
    }
  }

  .footer-first-region {
    clear: both;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin: 50px -50vw 20px;
    max-width: none;
    .bean-24 { //transparent overlay
      position: absolute;
      width: 100%;
      height: 400px;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, .0001);
      z-index: 99999;
      @include break1 {
        height: 1px;
      }
    }
  }

  .footer-second-region {
    clear: both;
    @include full-bg(lighten($darkgray, 10%));
    padding: 20px 10px 0px;
    a {
      color: $white;
      text-decoration: none;
      &:hover {
        color: lighten($darkgray, 50%);
      }
    }
  }
  
  .footer-contact {
    margin: 0 0 20px;
    &.phone {
      background: url('../images/phone.png') 0px 5px no-repeat;
      padding: 0px 0px 0px 45px;
    }
    &.email {
      background: url('../images/email.png') 0px 5px no-repeat;
      padding: 0px 0px 0px 45px;
    }
    &.payment {
      background: none;
      padding: 0px 0px 0px 45px;
    }
    .phone1,
    .email1 {
      color: $wlx-main;
      @include font-size($h2-size);
      font-weight: bold;
    }
  }
}