// -- TYPO SETTINGS -- //

// ** BASE
// -- Set a base font-size (dont use px!) & font-family
$base-font-size: 12;
$base-font-family: 'Ubuntu', 'Roboto', 'Arial', sans-serif;

// ** HEADINGS
// -- Set your h1, h2, ... size (don't use px!) and colour
$h1-size: 34;
$h1-colour: $wlx-main;
$h2-size: 26;
$h2-colour: $text-grey;
$h3-size: 22;
$h3-colour: $text-grey;
$h4-size: 18;
$h4-colour: $text-grey;
$h5-size: 16;
$h5-colour: $text-grey;
$h6-size: 14;
$h6-colour: $text-grey;
