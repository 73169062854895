.node {
  // 404
  .error-button {
    @include font-awesome('\f104', #aaa, 20px, 'before', middle, -3px 10px 0 0);
    &:hover {
      &:before {
        color: $red;
      }
    }
  }
}
