.header-top {
  #logo {
    float: left;
    width: 5%;
    min-width: 80px;
    position: relative;
    z-index: 1;
    img {
      @extend %image;
    }
  }
}

// -- HEADER -- //
header {
  .region_logo {
    //@include span-columns(4);
    //@include omega(3n);
    //@include break_tb {
    //  @include omega-reset(3n);
    //  @include span-columns(3);
    //  @include omega(4n);
    //}
    width: 40%;
    max-width: 280px;
    float: left;
    position: relative;
    z-index: 7;
    @include break_tb {
      width: 20%;
    }
    img {
      width: 100%;
      max-width: 280px;
      height: auto;
      margin: 10px 0 5px;
    }
  }
  
  .region_navigation {
    float: right;
    width: 100%;
    margin-top: -85px;
    position: relative;
    z-index: 6;
    @include break_tb {
      //@include span-columns(10);
      width: 75%;
      text-align: right;
      margin-top: 0px;
      //.icon-help {
      //  background: url('../images/help.svg') no-repeat !important;
      //  background-size: 40px 40px !important;
      //  width: 40px;
      //  height: 40px;
      //  margin: 7px 20px 0;
      //  font-size: 0px !important;
      //}
      // HELP DROPDOWN
      li.icon-help {
        & > a {
          background: url('../images/help.svg') no-repeat !important;
          background-size: 40px 40px !important;
          width: 40px;
          height: 40px;
          margin: 7px 20px 0;
          font-size: 0px;
          .caret {
            display: none !important;
          }
        }
      }

      a.icon-ticket {
        border: 1px solid $wlx-main !important;
        margin: 14px 20px 0 5px !important;
        padding: 0 5px !important;
        font-size: 13px !important;
        text-transform: uppercase !important;
        &:hover {
          color: $wlx-main !important;
        }
      }
      .icon-profiel {
        background: url('../images/user.svg') no-repeat !important;
        background-size: 30px 30px !important;
        width: 30px;
        height: 30px;
        margin: 10px 15px 0 0;
        font-size: 0px !important;
      }
      .icon-login-register {
        background: url('../images/login-registreren.svg') no-repeat !important;
        background-size: 30px 30px !important;
        width: 30px;
        height: 30px;
        margin: 10px 15px 0 0;
        font-size: 0px !important;
      }
      .icon-logout {
        background: url('../images/exit.svg') no-repeat !important;
        background-size: 30px 30px !important;
        width: 30px;
        height: 30px;
        margin: 10px 15px 0 0;
        font-size: 0px !important;
      }
      .icon-cart {
        background: url('../images/cart.svg') no-repeat !important;
        background-size: 30px 30px !important;
        width: 30px;
        height: 30px;
        margin: 10px 20px 0 0;
        font-size: 0px !important;
      }
    }
  }

  .header_second {
    clear: both;
    padding: 40px 0;
    min-height: 120px;
    @include full-bg($darkgray);
  }
  //nav {
  //  width: 100%;
  //
  //  @include break1 { /* min-width 720px */
  //    display: block;
  //    float: right;
  //    width: auto;
  //  }
  //  ul {
  //    @include full-bg($black);
  //    margin: 0px 10px;
  //    padding: 15px;
  //    @include break1 { /* min-width 720px */
  //      background: none;
  //      margin: 10px;
  //      padding: 0;
  //    }
  //    @include break2 { /* min-width 1200px */
  //      margin: 0px;
  //    }
  //    li {
  //      @include break1 { /* min-width 720px */
  //        float: left;
  //        margin: 0;
  //        padding: 0;
  //      }
  //      a,
  //      a:active,
  //      a:link,
  //      a:visited {
  //        color: $white;
  //        font-size: 1.8rem;
  //        padding: 1.1rem 0;
  //        display: block;
  //        @include break1 { /* min-width 720px */
  //          color: $black;
  //          padding: 10px 0;
  //          margin: 0 20px 0 0;
  //          font-size: 1.5rem;
  //        }
  //        @include break2 {
  //          font-size: 1.7rem;
  //        }
  //      }
  //      a:hover {
  //        @include break1 { /* min-width 720px */
  //          color: $red;
  //        }
  //      }
  //      a.active {
  //        @include break1 { /* min-width 720px */
  //          color: $red;
  //          border: 1px solid $red;
  //          padding: 10px 20px;
  //        }
  //      }
  //    }
  //
  //  }
  //}
}

//DOMAIN SEARCH

form#wlx-domains-search-form,
form#wlx-domains-search-form--2 {
  padding: 0 10px;
  .form-item-search-domain {
    position: relative;
    input {
      @include span-columns(9);
      padding: 0 0 0 70px;
      font-size: 20px;
      color: $wlx-main;
    }
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 70px;
      content: 'www.';
      padding: 5px 0 0 15px;
      color: $wlx-main;
      font-size: 20px;
    }
  }
  input[type=submit] {
    @include span-columns(3);
    font-size: 14px;
    height: 40px;
  }
}

form#wlx-domains-search-form--2 {
  padding: 10px 0 0;
}

// CART BLOCK
#header-cart-block {
  
  a.cart-qty-link {
    display: none;
    
    @include break_tb {
      display: block;
      text-decoration: none;
      font-size: 11px;
      font-weight: bold;
      background: $bittersweet;
      color: $white;
      padding: 0px 4px;
      position: absolute;
      top: 35px;
      z-index: 1;
      &.user-auth {
        right: 105px;
      }
      &.user-anon {
        right: 60px;
      }
    }  
  }
  a.cart-qty-empty-link {
    display: none;
  }
}

