// -- COLOURS -- //

// ** MAIN
$black:             #000000;
$white:             #FFFFFF;
$lightestgray:      #F8F8F8;
$lightergray:       #CCCCCC;
$lightgray:         #AAAAAA;
$mediumgray:        #777777;
$darkgray:          #2A363F;
$wlx-main:          #0482C4;
$red:               #CC0000;
$green:             #009B72;
$orange:            #F75C03;

$lightblue:         #E1EFFA;
$koamaru:           #374A67;
//$bittersweet:       #F87060;
$bittersweet:       #FFA800;
$veeam:             #194867;

// ** TABS
$tabs-background:   $wlx-main;
$tabs-text:         $white;
$tabshover:         lighten($tabs-background, 50%);

// ** ALERTS
//$error:             #E74C3C;
$error:             #FB3640;
$error-dark:        darken($error, 10%);
$status:            $green;
$status-dark:       darken($green, 10%);
$warning:           #EB9532;
$warning-dark:      #F2784B;

// ** CUSTOM SITE
$text-grey:         #333333;
$link-colour:       $mediumgray;
$button-colour:     $wlx-main;
$placeholder:       #A2A2A2;
$border-color:      #DDDDDD;
