body.page-user {
  .main {
    background: darken($lightestgray, 5%);
    border-top: 1px solid $white;
    padding: 20px;
    .profile,
    .view-commerce-user-orders,
    .entity-commerce-order {
      color: $text-grey;
    }
  }
  #breadcrumbs {
    margin: 0;
  }
  .tabs {
    li.active {
      background: lighten($wlx-main, 5%);
    }
  }
  &.page-user-reset {
    .main {
      background: none;
      color: $text-grey;
      padding: 20px 0;
    }
  }
}

#user-login,
#user-pass,
#user-register-form {
  float: none;
  background-color: #F4F4F4;
  width: 100%;
  max-width: 500px;
  padding: 20px;
  margin: 0px auto;
  text-align: center;
  border: 1px solid $lightergray;
  label {
    display: none;
  }
  * {
    width: 100%;
  }
  > div {
    margin: 10px 0px;
  }
  input[type=text],
  input[type=password] {
    padding: 10px;
    border: 1px solid $lightgray;
  }
  input[type=submit] {
    margin-left: 0;
    border: 0;
    color: $white;
  }
  small {
    display: none;
  }
  fieldset#edit-account {
    border: none;
    padding: 0;
    input {
      border: 1px solid $lightgray;
      margin: 0 0 15px;
    }
  }
  #edit-actions {
    margin: 15px 0;
  }
}
#user-pass {
  input[type=text] {
    margin-top: 20px;
  }
}
.page-user-password {
  .sub-header {
    display: none;
  }
}
#user-login {
  small {
    display: none;
  }
}
.confirm-parent {
  .password-confirm {
    .ok {
      color: $status;
    }
    .error {
      color: $error;
    }
  }
}

.profile {
  background: $lightestgray;
  border: 1px solid $lightergray;
  padding: 20px;
  label {
    font-size: 20px;
    font-weight: normal;
    color: $wlx-main;
  }
  a {
    color: $darkgray;
    &:hover {
      color: $wlx-main;
    }
  }
  fieldset.group-facturatie-gegevens,
  fieldset.profile-account {
    border: 0;
    padding: 0;
    margin: 0 0 30px;
    legend {
      padding: 0;
      color: $wlx-main;
      font-size: 20px;
      font-weight: normal;
      margin: 0 0 10px;
    }
  }
  .field-name-field-address {
    margin: 0 0 30px;
  }
}

#user-profile-form {
  legend {
    font-size: 20px;
    font-weight: normal;
    color: $wlx-main;
  }
  fieldset {
    border: 1px solid $lightergray;
    background: $lightestgray;
    padding: 20px;
    margin: 20px 0;
    small {
      display: none;
    }
    input[type='text'],
    input[type='password'] {
      border: 1px solid $lightergray;
      width: 100%;
    }
    label {
      font-size: 16px;
      color: $lightgray;
      margin: 20px 0 10px 10px;
    }
    &.group-facturatie-gegevens {
      fieldset {
        border: 0;
        padding: 0;
        margin: 0;
        legend {
          display: none;
        }
      }
    }
    &.group-technische-gegevens {
      fieldset {
        border: 0;
        padding: 0;
        margin: 0;
        legend {
          display: none;
        }
      }
    }
  }
  .field-name-field-telephone {
    label {
      &:after {
        content: ' *';
        color: $red;
      }
    }
  }
}

.account-wrapper {
  width: 200px;
  display: inline-block;
  padding: 5px 0 15px;
  a {
    display: inline-block;
    text-indent: -999999px;
    font-size: 0;
    margin: 0 15px 0 0;
    background-size: 30px 30px;
    &.header-help {
      background: url('../images/header_help.png') 0px 1px no-repeat;
      width: 32px;
      height: 33px;
      margin: 0 20px 0 0;
    }
    &.user-login {
      background: url('../images/header_login.png') 0px 1px no-repeat;
      width: 30px;
      height: 32px;
      margin: 0 20px 0 0;
    }
    &.user-logout {
      background: url('../images/header_logout.png') 0px 1px no-repeat;
      width: 30px;
      height: 32px;
      margin: 0 20px 0 0;
    }
    &.user-register,
    &.user-account {
      background: url('../images/header_profiel.png') 0px 0px no-repeat;
      width: 32px;
      height: 32px;
      margin: 0 15px 0 0;
    }
    &.header-cart {
      background: url('../images/header_winkelwagen.png') 0px 0px no-repeat;
      width: 32px;
      height: 30px;
      margin: 0;
    }
  }
}