.md-slide-fullwidth {
  .yellow {
    background: #FFCE00;
    padding: 20px;
  }
  .red {
    background: #FF0044;
    padding: 20px;
  }
  .dark {
    background: $darkgray;
    color: $white;
    padding: 20px;
  }
}