.view-id-blog {
  .views-row {
    clear: both;
  }
  .views-field-field-blog-image {
    float: left;
    margin: 0 10px 10px 0;
    @include span-columns(3);
    img {
      width: 90%;
      height: auto;
    }
  }
  .views-field-body {
    @include span-columns(9);
  }
}

.node-type-blog {
  .field-name-field-blog-image {
    width: 25%;
    margin: 0 20px 20px 0;
    float: left;
    img {
      width: 100%;
      height: auto;
    }
  }  
}

.social-share-facebook {
  @include font-awesome($facebook, $wlx-main, 25px, before, middle, 10px 15px 0 0);
  font-size: 0;
}

.social-share-twitter {
  @include font-awesome($twitter, $wlx-main, 25px, before, middle, 10px 15px 0 0);
  font-size: 0;
}

.social-share-linkedin {
  @include font-awesome($linkedin, $wlx-main, 25px, before, middle, 10px 15px 0 0);
  font-size: 0;
}