// -- FORMS -- //

// ** INPUTS
input {
  @include placeholder {
    color: $placeholder;
    opacity: 1;
  }
}
// Types email, number, password, search, tel, text, url, color, date, datetime,
// datatime-local, month, time, week
#{$all-text-inputs},
textarea {
  border-radius: 0px;
  height: 40px;
  padding: 0 10px;
  width: 100%;
  outline: none;
  border: 1px solid $border-color;
  &:focus,
  &:focus:hover {
    outline: 0;
  }
  &:disabled {
    background: lighten($border-color, 10%);
  }
}

input.admin-menu-search {
  height: auto;
}

textarea {
  padding: 10px;
  height: auto;
  &:focus,
  &:focus:hover {
    outline: 0;
  }
}
input[type='range'] {
  -webkit-appearance: none;
  width: 100%;
  border-radius: 5px;
  height: 5px;
  border: 1px solid $border-color;
  background-color: $border-color;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: lighten($border-color, 20%);
    border: 1px solid $border-color;
    @include square(20px);
    border-radius: 10px;
    cursor: pointer;
  }
  &:focus,
  &:focus:hover {
    outline: 0;
  }
}
input[type='radio'],
input[type='checkbox'] {
  -webkit-appearance:none;
  display: inline-block;
  position: relative;
  //top: 5px;
  margin: 0 10px 0 0;
  padding: 0;
  background: $white;
  border: 1px solid $border-color;
  border-radius: 0;
  @include square(20px);
  outline: none;
  @include transition(border-color $duration $timing, background-color $duration $timing);
  &:hover,
  &:focus {
    border-color: darken($border-color, 20%);
    outline: 0;
    cursor: pointer;
  }
  &:disabled {
    opacity: .5;
    background: #E2E2E2;
    &:hover {
      border-color: $border-color;
      cursor: default;
    }
  }
  & + label {
    display: inline-block;
  }
}
input[type='checkbox'] {
  background-position: 50% 50%;
  @include transition(background-color $timing .1s);
  &:checked,
  &:disabled:checked {
    background: url('../images/checkboxcheck.png');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border: 0;
  }
}
input[type='radio'] {
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 0px $lightergray;
  @include transition(box-shadow .1s cubic-bezier(0.28,0.63,0.77,0.17));
  &:checked,
  &:disabled:checked {
    background: $wlx-main;
    box-shadow: inset 0px 0px 0px 5px white;
  }
}
// Types button, reset, submit
#{$all-button-inputs} {
  @include button;
  @include font-size(20);
  outline: 0;
  &:focus,
  &:focus:hover {
    outline: 0;
  }
}
input.error {
  color: $error;
}

// ** LABELS
label {
  margin-bottom: 5px;
  display: inline-block;
}

// ** SELECTS
select {
  height: 40px;
  width: 100%;
  background-color: $white;
  border: 1px solid $border-color;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, $border-color 50%), linear-gradient(135deg, $border-color 50%, transparent 50%);
  background-position: calc(100% - 20px) 18px, calc(100% - 15px) 18px, calc(100% - 2.5em) 8px;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  padding: 0 40px 0 10px;
  border-radius: 0;
  &:focus,
  &:focus:hover {
    outline: 0;
  }
  &:disabled {
    background: lighten($border-color, 10%);
  }
  // Remove fugly arrow in IE
  &::-ms-expand {
    display: none;
  }
}

// ** HINTS
small,
.password-suggestions {
  display: block;
  color: lighten(grey, 20%);
  margin-top: 10px;
  font-size: 11px !important;
}

// ** FIELDSETS
fieldset {
  border: 1px solid $border-color;
  legend {
    color: $text-grey;
    font-weight: bold;
    padding: 0 10px;
  }
}

// ** WEBFORMS
.node-type-webform {
  label.required {
    &:after {
      content: ' *';
      color: $wlx-main;
    }
  }
}
.webform-layout-box {
  label,
  input,
  select,
  textarea {
    font-size: 1.4rem;
  }
  label {
    background: #F9F9F9;
    padding: 10px;
    display: block;
    margin: 0;
    color: #888;
    border: 1px solid $lightergray;
    border-bottom: 0;
    @include break1 {
      display: inline-block;
      width: 125px;
      border: 1px solid $lightergray;
      border-right: 0;
      height: 40px;
      padding: 9px 10px;
    }
  }
  input:not([type=checkbox]),
  select {
    @include break1 {
      display: inline-block;
      width: calc(100% - 125px);
      vertical-align: top;
    }
  }
  input[type=checkbox] {
    top: 0;
  }
  .form-item {
    display: block;
    margin: 20px 0;
    font-size: 0;
  }
  .form-type-checkbox label {
    background: none;
    border: 0 !important;
    padding: 0;
    height: auto;
    vertical-align: middle;
    width: auto;
  }
  input[type=checkbox]:checked,
  input[type=checkbox]:disabled:checked {
    border: 1px solid $lightergray;
    background: url('../images/checkboxcheck.png');
  }
  .webform-component--right--questions,
  .webform-component--right--your-interests {
    @include break1 {
      & > label {
        width: 100%;
        border: 1px solid $lightergray;
        border-bottom: 0;
      }
    }
  }
}
