.view-office-365-new {
  .views-row {
    margin-right: 2px !important;
    @include span-columns(12);
    @include omega(1n);
    @include break1 {
      @include omega-reset(1n);
      @include span-columns(4);
      @include omega(3n);
    }
    h2 {
      display: block;
      padding: 20px 70px;
      background: $wlx-main;
      color: $white;
      font-size: 24px;
      font-weight: lighter;
      border-top: 2px solid $white;
      position: relative;
      text-align: center;
      em {
        color: $wlx-main;
      }
    }
    .field-name-field-prijs-per-maand {
      background: $veeam;
      position: relative;
      margin-top: 25px;
      color: $white;
      font-size: 2.4rem;
      width: 100%;
      display: block;
      padding: 10px 0;
      text-align: center;
      &:before {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: $veeam;
        border-width: 15px;
        margin-left: -15px;
      }
    }
    form.commerce-add-to-cart {
      text-align: center;
      margin: 0 0 20px;
      .form-item-attributes-field-office365-backup {
        label {
          display: none;
        }
        margin: 15px 0;
      }
    }
    .field-name-body {
      h2 {
        color: $wlx-main;
        font-size: 18px;
        font-weight: lighter;
        margin: 10px 0 3px;
        background: none;
        text-align: left;
        clear: left;
      }
      hr {
        height: 0px;
        border-top: 1px solid $lightgray;
        margin: 20px 0;
        display: inline-block;
      }
      ul.office365-icons {
        display: inline-block;
        padding: 0;
        margin: 0;
        li {
          list-style: none;
          float: left;
          width: 25%;
          text-align: center;
          text-indent: 0px;
          padding-left: 0px;
          p {
            text-align: center;
          }
        }
      }
    }
  }
}