.tb-megamenu {
  background: none;
  display: block;
  @include break_tb {
    display: inline-block;
    margin: 20px 0 0;
  }

  /* HAMBURGER */
  .btn-navbar {
    float: right;
    margin: 20px 10px 0 0;
    border: none;
    background: $darkgray;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    font-size: 25px;
    &:hover {
      background: lighten($darkgray, 5%);
      color: $white;
    }
    i.fa-reorder {
      font-size: 26px;
      font-weight: lighter;
    }
  }
  .nav-collapse {
    margin-top: 50px;
    background: $darkgray;
    @include break_tb {
      margin-top: 0px;
      background: $white;
    }
  }

  .mega-inner {
    background: lighten($darkgray, 2%);
    @include break_tb {
      background: $white;
    }
  }

  .nav-collapse .nav > li.active > a:hover,
  .nav-collapse .nav > li.active > a:focus {
    background: $white;
  }

  ul.tb-megamenu-nav {
    /* float: right; */
    margin: 10px 0 0;
  }
  .dropdown-menu {
    border: 1px solid $lightestgray;
    border-bottom: 0;
    min-height: 50px;
    li {
      a {
        &:hover {
          color: lighten($wlx-main, 20%);
        }
      }
    }
  }
  .nav > .active > a,
  .nav > .active > a:hover,
  .nav > .active > a:focus,
  .nav > li > a {
    background: none;
    color: $wlx-main;
  }
  .nav > .active > a:hover {
    color: lighten($wlx-main, 20%);
  }

  .nav > li.dropdown.open > .dropdown-toggle {
    color: lighten($wlx-main, 20%);
  }

  .nav > li > a {
    border: 0;
    font-family: $base-font-family;
    font-weight: normal;
    font-size: 16px;
    &:hover {
      color: lighten($wlx-main, 20%);
    }
  }
  
  //.tb-megamenu-item.tb-hosting {
  //  > .mega-group-title {
  //    display: none;
  //  }
  //  padding: 20px;
  //  @include break_tb {
  //    background: $lightestgray;
  //  }
  //  .tb-megamenu-column-inner {
  //    @include break_tb {
  //      background: $lightestgray;
  //    }  
  //  }
  //}
  //
  //li.tb-shared-hosting {
  //  @include span-columns(4);
  //  @include omega(3n);
  //  float: left !important;
  //  a.mega-group-title {
  //    display: block;
  //    @include break_tb {
  //      /* padding-top: 70px !important;
  //      background: url('../images/shared_hosting.png') top center no-repeat !important; */
  //    }
  //  }
  //}
  //li.tb-reseller-hosting {
  //  @include span-columns(4);
  //  @include omega(3n);
  //  float: left !important;
  //  @include break_tb {
  //    background: $lightestgray;
  //  }
  //  padding: 0;
  //  margin: 0;
  //  a.mega-group-title {
  //    display: block;
  //    border: 0;
  //    @include break_tb {
  //      /* padding-top: 70px !important;
  //      background: url('../images/reseller_hosting.png') top center no-repeat !important; */
  //    }
  //  }
  //}
  //li.tb-hosting-vps {
  //  @include span-columns(4);
  //  @include omega(3n);
  //  float: left !important;
  //  a.mega-group-title {
  //    display: block;
  //    border: 0;
  //    @include break_tb {
  //      /* padding-top: 70px !important;
  //      background: url('../images/hosting_vps.png') top center no-repeat !important; */
  //    }
  //  }
  //}
  
  @include break_tb {
    .nav li.tb-cart > a {
      display: block;
      background: url('../images/shopping-cart.png') top left no-repeat $wlx-main !important;
      width: 32px !important;
      height: 32px;
      text-indent: -9999999px;
      padding: 0;
      margin: 5px 0 0;
    }
  }
  .nav li.dropdown.active > .dropdown-toggle,
  .nav li.dropdown.open.active > .dropdown-toggle,
  .nav > li.dropdown.open.active > a:hover {
    background: $white;
    color: $wlx-main;
    .caret {
      border-top-color: $black;
      border-bottom-color: $black;
    }
  }
}

.level-2.dropdown-submenu {
  background: darken($darkgray, 2%);
  @include break_tb {
    background: $white;
  }
}