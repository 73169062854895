.view-hp-promo {
  .views-row {
    article header {
      display: none;
    }
    .container-24.grid-8.prefix-1 > div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      > h2 {
        display: none;
      }
      .commerce-product-field-commerce-price, 
      .commerce-product-field-field-display-price {
        order: 2;
        font-size: 25px;
        text-align: center;
        padding: 30px 0;
        color: $wlx-main;
        .field-name-field-display-price {
          &:after {
            content: ' *';
            color: $wlx-main;
          }
        }
      }
      .field-name-body {
        order: 1;
      }
      .field-name-field-product {
        order: 3;
        text-align: center;
      }
    }
  }
  .full-bar {
    color: $white;
    text-align: center;
    margin-left: calc(-100vw / 2 + 1180px / 2);
    margin-right: calc(-100vw / 2 + 1180px / 2);
    margin-top: 50px;
    margin-bottom: 0;
    padding: 30px 0;
    font-size: 25px;
    &.dark {
      background: $darkgray;
    }
    &.light {
      background: $darkgray;
      margin-top: 0px;
      margin-bottom: 50px;
    }
  }
  .hp-promo-banner {
    height: 300px;
    background: url('../images/hp-promo-banner.jpg') center center no-repeat #EEE;
    background-size: cover;
    margin-left: calc(-100vw / 2 + 1180px / 2);
    margin-right: calc(-100vw / 2 + 1180px / 2);
    text-align: center;
    color: #FFF;
    padding: 150px 0;
    font-size: 25px;
  }
  .hp-promo-gray-blocks {
    display: flex;
    justify-content: space-between;
    .hp-promo-gray-block {
      background: rgb(247, 247, 247);
      padding: 30px 50px;
      margin: 70px 20px 0;
      text-align: center;
      max-width: 47%;
      img {
        width: 300px !important; 
        height: 300px !important;
      }
    }
  }
}
