.view-id-team {
  margin: 10px 0;
  h2 {
    text-align: center;
    font-size: 28px;
    font-weight: lighter;
    color: $koamaru;
    margin: 15px 0 20px;
  }
  .views-row {
    @include span-columns(6);
    @include omega(2n);
    img {
      width: 100%;
      height: auto;
    }
    .views-field-title {
      font-size: 15px;
      color: $koamaru;
    }
    .views-field-field-functie {
      font-size: 13px;
      color: $mediumgray;
    }

    //@include break0 {
    //  @include omega-reset(2n);
    //  @include span-columns(4);
    //  @include omega(3n);
    //  text-align: center;
    //  .views-field-title {
    //    font-size: 17px;
    //  }
    //  .views-field-field-functie {
    //    font-size: 14px;
    //  }
    //}
    @include break1 {
      //@include omega-reset(3n);
      //@include span-columns(2);
      //@include omega(6n);
      @include omega-reset(2n);
      @include span-columns(3);
      @include omega(4n);
      text-align: center;
      .views-field-title {
        font-size: 17px;
      }
      .views-field-field-functie {
        font-size: 14px;
      }
    }
  }
}