.view-id-testimonials {
  .views-row {
    padding: 15px;
    clear: both;
    //border-bottom: 1px solid $lightergray;
  }
  .views-row-last {
    border: none;
  }

  .group-testimonial-left {
    @include span-columns(3);
    img {
      width: 100%;
      max-width: 220px;
      height: auto;
    }
  }

  .group-testimonial-right {
    @include span-columns(9);
    .field-name-field-contactpersoon,
    .field-name-field-functie {
      display: inline-block;
      margin: 10px 0;
      color: $lightgray;
    }
    .field-name-field-functie:before {
      content: '-';
    }
  }
}