// -- MAIN STRUCTURE -- //
// -- For structure see http://neat.bourbon.io/
// -- For examples see http://neat.bourbon.io/examples/

// ** BODY
body {
  width: 100%;
  overflow-x:hidden;
  margin: 0;
  .page {
    //@include outer-container;
    padding: 8px 10px 0;
    min-height: 200px;
    @include break2 {
      max-width: 1200px;
      margin: 0 auto;
    }
  }
  .header-top {
    @include full-bg($wlx-main);
    padding: 5px 10px;
    color: $white;
    text-align: right;
    @include break2 {
      max-width: 1200px;
      margin: 0 auto;
    }
  }
}

// ** HEADER - FOOTER
header *,
.main *,
footer * {
  box-sizing: border-box;
}
header,
footer {
  @include outer-container;
}

// ** SIDEBARS
// -- Try to use span-columns to style sidebars and such
body.sidebar-first {
  .page-wrapper {
    @include break1 {
      .sidebar-first {
        @include span-columns(4);
      }
      .main {
        @include span-columns(8);
      }
    }
    @include break2 {
      .sidebar-first {
        @include span-columns(3);
      }
      .main {
        @include span-columns(9);
      }
    }
  }
}