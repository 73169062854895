.view-ssl-certificaten,
.view-linux-hosting,
.view-windows-hosting,
.view-office-365-new {
  .views-row {
    position: relative;
    padding: 45px 0px 20px !important;
    &:before {
      position: absolute;
      display: block;
      width: 100%;
      height: 50px;
      top: 0px;
      left: 0;
      content: '';
      line-height: 50px;
      text-align: center;
    }
    &.views-row-1 {
      &:before {
        background: $bittersweet;
        content: 'AANGERADEN';
        color: $white;
        font-size: 19px;
      }
    }  
  }
}

.view-id-gdpr {
  .views-row {
    position: relative;
    padding: 45px 0px 20px !important;
    &:before {
      position: absolute;
      display: block;
      width: 100%;
      height: 50px;
      top: 0px;
      left: 0;
      content: '';
      line-height: 50px;
      text-align: center;
    }
    //&.views-row-1 {
    //  &:before {
    //    background: $bittersweet;
    //    content: 'AANGERADEN';
    //    color: $white;
    //    font-size: 19px;
    //  }
    //}
  }
}

.view-office-365-new {
  .views-row {
    &:before {
      height: 60px;
      line-height: 60px;
    }
  }
}

.view-office-365,
.view-exchange-online,
.view-cloud-servers-overzicht,
.view-id-onedrive,
.view-id-sharepoint {
  tbody {
    tr {
      &.field_title_field,
      &.field_name {
        td {
          &:before {
            width: 100%;
            height: 50px;
            line-height: 50px;
            background: $white;
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            font-size: 19px;
            color: $white;
            z-index: 1;
          }
          &.col-2 {
            &:before {
              background: $bittersweet;
              content: 'AANGERADEN';
              border-bottom: 2px solid $white;
              z-index: 1;
            }
          }
        }
      }
    }
  }
}
