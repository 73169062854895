.view-id-encryptie {
  .views-row {
    @include span-columns(12);
    @include omega(1n);
    //@include break1 {
    //  @include omega-reset(1n);
    //  @include span-columns(4);
    //  @include omega(3n);
    //  clear: none !important;
    //}
    .views-field-title {
      display: block;
      padding: 20px 0px;
      background: $wlx-main;
      color: $white;
      font-size: 24px;
      font-weight: lighter;
      border-top: 2px solid $white;
      position: relative;
      text-align: center;
    }
    .views-field-field-prijs-per-maand {
      background: $veeam;
      position: relative;
      margin-top: 25px;
      color: $white;
      font-size: 19px;
      width: 100%;
      display: block;
      padding: 10px 0;
      text-align: center;
      &:before {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: $veeam;
        border-width: 15px;
        margin-left: -15px;
      }
    }
    form.commerce-add-to-cart {
      text-align: center;
      margin: 20px 0;
      .form-item-attributes-field-office365-backup {
        label {
          display: none;
        }
        margin: 15px 0;
      }
    }
    .views-field-field-omschrijving {
      .label {
        width: 60%;
        float:left;
        padding: 0 10px;
      }
      .value {
        width: 40%;
        float:left;
        padding: 0 0 0 15px;
      }
      p {
        margin: 20px 0 10px;
        clear: left;
        display: inline-block;
        background: $lightestgray;
        width: 100%;
        padding: 10px;
      }
    }
  }
}
