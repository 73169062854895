/* *** COOKIE CONTROL *** */
#cccwr .ccc-about,
#cccwr button.ccc-expand,
#cccwr a.ccc-icon,
#cccwr #ccc-cookies-switch,
#cccwr #ccc-icon button {
  display:none !important;
}

#cccwr .ccc-widget {
  bottom: 15px;
  left: 15px;
}

#cccwr.ccc-dark div.ccc-outer {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: none;
  width: 100%;
  max-width: 350px;
  padding:10px 10px 10px 80px;
  border:0;
  background: url('../images/cookies.png') 20px 30px no-repeat $darkgray;
  color: $white;
}

#cccwr.ccc-dark p.ccc-intro {
  font-size:13px;
}

#cccwr.ccc-dark div.ccc-inner h2 {
  background: none;
  padding:10px;
}

#cccwr.ccc-dark div.ccc-inner a {
  color: $white;
}

#cccwr.ccc-dark div.ccc-inner a:hover {
  color: $lightergray;
}

button.ccc-close {
  background: url('../images/cross_small_white.png') 0px 0px no-repeat !important;
  width: 15px;
  height: 15px;
  padding: 0 !important;
}