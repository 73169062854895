article.node-news {
  h1 {
    color: $red;
    font-size: $h1-size;
    margin: 0 0 30px;
  }

  .field-name-field-news-image {
    margin: 0 0 30px;

    img {
      width: 100%;
      height: auto;
    }

    @include break1 {
      width: calc(50% - 15px);
      float: left;
      margin: 0 30px 30px 0;
    }
  }

  .field-name-body {
    text-align: justify;
  }
}