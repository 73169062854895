.view-office-365,
.view-exchange-online,
.view-cloud-servers-overzicht,
.view-id-onedrive,
.view-id-sharepoint {
  tbody {
    tr {
      &.odd {
        background: $lightestgray;
      }
      th {
        padding: 10px;
        width: 15%;
      }
      td {
        text-align: center !important;
        width: 25%;
        position: relative;
      }
      &.field_title_field {
        td {
          padding: 50px 0 20px;
          color: $white;
          font-size: 24px;
          border-right: 2px solid $white;
          position: relative;
          .field-content {
            background: $wlx-main;
            padding: 20px 0;
          }
        }
      }
      &.field_commerce_price {
        color: $mediumgray;
        height: 40px;
        font-size: 15px;
      }
      &.field_field_prijs_maand {
        background: $white;
        td {
          .field-content {
            position: relative;
            color: $white;
            background: $veeam;
            font-size: 19px;
            padding: 10px 0;
            margin: 20px 0;
            width: 100%;
            border-right: 2px solid $white;
            &:before {
              bottom: 100%;
              left: 50%;
              border: solid transparent;
              content: ' ';
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
              border-bottom-color: $veeam;
              border-width: 15px;
              margin-left: -15px;
            }
          }
        }
      }
      &.field_add_to_cart_form {
        form.commerce-add-to-cart {
          padding: 15px 0;
        }
      }
    }
  }

  .office365-respo,
  .office365-norespo {
    display: none;
    .toggle-wrapper {
      display: none;
    }
  }

  .office365-respo {
    .field-title_field-value {
      background: $wlx-main;
      color: $white;
      padding: 20px;
      margin: 0 0 2px;
      position: relative;
      &:after {
        content: '+';
        position: absolute;
        top: 0px;
        right: 20px;
        color: $white;
        font-size: 35px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .field-field_prijs_maand-value {
      position: relative;
      color: $white;
      background: $koamaru;
      font-size: 19px;
      padding: 10px 0;
      margin: 20px auto;
      width: 70%;
      text-align: center;
      &:before {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: $koamaru;
        border-width: 15px;
        margin-left: -15px;
      }
    }
    .field_add_to_cart_form {
      form.commerce-add-to-cart {
        input {
          width: 100%;
        }
      }
    }
    .field-field_description-value {
      text-align: center;
    }
    .row-wrapper {
      border: 2px solid $white;
      .field-label {
        float: left;
        width: 49%;
        background: $lightestgray;
        padding: 20px 10px;
        text-align: center;
      }
      .field-value {
        float: right;
        width: 49%;
        text-align: center;
      }
    }
  }
}

.view-cloud-servers {
  .view-grouping-header,
  table caption {
    color: $wlx-main;
    margin: 20px 0;
    font-size: 28px;
    font-weight: lighter;
    text-align: left;
  }
  //.view-grouping-content {
  //  caption {
  //    background: $koamaru;
  //    color: #FFF;
  //  }
  //}
}

.view-cloud-servers-overzicht {
  .views-row {
    @include span-columns(12);
    @include omega(1n);
    @include break1 {
      @include omega-reset(1n);
      @include span-columns(4);
      @include omega(3n);
    }
    background: $lightblue;
    text-align: center;
    color: $koamaru;
    font-size: 14px;
    padding: 30px 0;
    h2 {
      color: $wlx-main;
      margin: 20px 0;
      font-size: 28px;
      font-weight: lighter;
    }
  }
  h2 {
    display: block;
    padding: 60px 15px 20px;
    background: $wlx-main;
    color: $white;
    font-size: 24px;
    font-weight: lighter;
    border-right: 2px solid $white;
    position: relative;
  }
  .field_name_1 {
    a {
      @include button;
      @include font-size(20);
      margin: 20px 0;
    }    
  }
}

.cloud_server_detail {
  .row {
    clear: both;
    padding: 20px 0 15px 10px;
    min-height: 60px;
    &.even {
      background: $lightestgray;
    }
    .label {
      width: 30%;
      max-width: 150px;
      float: left;
      text-align: left;
      font-weight: bold;
    }
    .value {
      width: 70%;
      float: left;
      max-width: 150px;
    }
  }
}