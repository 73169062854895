// -- FONTS -- //

// ** CUSTOM FONTS
// -- Use Google fonts when you can!
// @include font-face(SourceSansPro, '../fonts/Source_Sans_Pro/SourceSansPro-Regular');
// @include font-face(SourceSansPro, '../fonts/Source_Sans_Pro/SourceSansPro-Bold', bold);
// @include font-face(SourceSansPro, '../fonts/Source_Sans_Pro/SourceSansPro-Italic', normal, italic);

// Google fonts
// @import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Ubuntu');

// Webtype
// @import url('http://cloud.webtype.com/css/CXXXX.css');

// ** FONTAWESOME
// -- Don't include it if you don't use it!
@include font-face(FontAwesome, '../fonts/fontAwesome/fontawesome-webfont');
