.banner {
  @include outer-container;
  @include full-bg(#FFF);
  text-align: center;
  margin-top: 1px;
  img {
    width: 100%;
    max-width: 1180px !important;
    height: auto;
  }
  p {
    margin: 0;
    padding: 0;
  }
}

.slick--optionset--willux-slider {
  height: 300px;
  margin: 0 0 0 10px;
}

.views_slideshow_cycle_main {
  margin: 0 0 0 10px;
}