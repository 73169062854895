  .usp-block {
    width: 100%;
    margin: 0;
    @include break1 {
      width: calc((100% - 40px) / 4);
      margin-top: 10px;
      margin-right: 10px;
      min-height: 200px;
    } 
    float: left;
    padding: 75px 5px 5px;
    text-align: center;
    background: $white;
    border: 1px solid $lightgray;
    margin-bottom: 10px;
    font-size: 14px;
    &.budget {
      background: url('../images/budget.jpg') center 15px no-repeat $white;
    }
    &.betrouwbaar {
      background: url('../images/betrouwbaar.jpg') center 15px no-repeat $white;
    }
    &.continuiteit {
      background: url('../images/continuiteit.jpg') center 15px no-repeat $white;
    }
    &.productiviteit {
      background: url('../images/productiviteit.jpg') center 15px no-repeat $white;
    }
    &.veilig {
      background: url('../images/veilig.jpg') center 15px no-repeat $white;
    }
    &.flexibel {
      background: url('../images/flexibel.jpg') center 15px no-repeat $white;
    }
    &.proactief {
      background: url('../images/proactief.jpg') center 15px no-repeat $white;
    }
    &.alacarte {
      background: $wlx-main;
      border: 1px solid $wlx-main;
      color: $white;
      h2 {
        color: $white;
        font-weight: bold;
      }
    }
  }

.view-cloud-workplace {
  .views-row {
    @include span-columns(4);
    @include omega(3n);
    form.commerce-add-to-cart {
      display: none;
    }
    .row {
      width: 100%;
      min-height: 20px;
      clear: both;
      margin: 5px 0;
      padding: 5px 0;
      .item {
        width: 75%;
        float: left;
      }
      .yes {
        float: left;
        width: 18%;
        min-height: 20px;
        text-align: center;
        background: url('/sites/default/files/check_small.png') center center no-repeat;
      }
      .no {
        float: left;
        width: 18%;
        min-height: 20px;
        text-align: center;
        background: url('/sites/default/files/cross_small.png') center center no-repeat;
      }
      .txt {
        float: left;
        width: 18%;
        min-height: 20px;
        text-align: center;
      }
    }
    .price_title {
      background: #ffa800;
      display: block;
      color: #FFF;
      padding: 2px 10px;
      text-align: center;
      font-size: 24px;
      font-weight: lighter;
    }
    .price_value {
      background: #E22200;
      display: block; color: #FFF;
      padding: 2px 10px;
      position: relative;
      text-align: center;
      margin-top: 25px;
      font-size: 2.4rem;
      font-weight: lighter;
      &:before {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: #E22200;
        border-width: 15px;
        margin-left: -15px;
      }
    }
  }
  h2 {
    display: block;
    //padding: 20px 0px;
    background: $wlx-main;
    color: $white;
    font-size: 24px;
    font-weight: lighter;
    border-top: 2px solid $white;
    position: relative;
    text-align: center;
    padding: 20px 0;
    margin: 0;
  }
  .field-name-field-prijs-per-maand {
    background: $veeam;
    position: relative;
    margin-top: 25px;
    margin-bottom: 0px;
    color: $white;
    font-size: 2.4rem;
    width: 100%;
    display: block;
    padding: 10px 0;
    text-align: center;
    &:before {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-bottom-color: $veeam;
      border-width: 15px;
      margin-left: -15px;
    }
  }
  .field-name-field-prijs-setup {
    p {
      margin: 0;
      background: $veeam;
      padding: 0 0 10px;
      color: $white;
      text-align: center;
      font-size: 1.4rem;
    }
  }
}

.bean-text-block {
  .row {
    width: 100%;
    min-height: 20px;
    clear: both;
    margin: 5px 0;
    padding: 5px 0;
    .item {
      width: 25%;
      float: left;
    }
    .yes {
      float: left;
      width: 25%;
      min-height: 20px;
      text-align: center;
      background: url('/sites/default/files/check_small.png') center center no-repeat;
    }
    .no {
      float: left;
      width: 25%;
      min-height: 20px;
      text-align: center;
      background: url('/sites/default/files/cross_small.png') center center no-repeat;
    }
    .txt {
      float: left;
      width: 25%;
      min-height: 20px;
      text-align: left;
    }
  }
}

a.voip {
  color: $white !important;
  &:hover {
    color: $white !important;
    text-decoration: none !important;
  }
}