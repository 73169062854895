// -- GENERAL -- //
// ** HTML
html {
  font-size:62.5%;
}

// ** BODY
body {
  //@include font-size($base-font-size);
  font-size: 1.4rem;
  line-height: 1.5;
  font-family: $base-font-family;
  color: $text-grey;
  font-weight: normal;
  & * {
    -webkit-font-smoothing: antialiased; //fix for Chrome font-rendering
  }
  @include break2 { /* min-width 1200px */
    #page-wrapper {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
    }
  }
}

// ** HEADING
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
}
h1 {
  color: $koamaru;
  font-size: 28px;
  font-weight: lighter;
}
h2 {
  color: $wlx-main;
  font-size: 18px;
  font-weight: lighter;
  margin: 10px 0 3px;
}
h3 {
  color: $h3-colour;
  @include font-size($h3-size);
}
h4 {
  color: $h4-colour;
  @include font-size($h4-size);
}
h5 {
  color: $h5-colour;
  @include font-size($h5-size);
}
h6 {
  color: $h6-colour;
  @include font-size($h6-size);
}

// ** LINKS
a {
  text-decoration: none;
  @include links($link-colour, $wlx-main);
  @include transition(all $duration $timing);
  &:after,
  &:before {
    @include transition(all $duration $timing);
  }
  &.button {
    @include button();
  }
}
.page {
  .main {
    a {
      text-decoration: underline;
    }
    ul {
      list-style-image: url('../images/bullet.png');
      li {
        list-style-position: inside;
        text-indent: -27px;
        //padding-left: 15px;
        padding-left: 27px;
      }
    }
  }
}

// ** LISTS
ul,
ol,
dl {
  @extend %m-p-reset;
  margin: 10px 0;
  padding-left: 15px;
  li {
    list-style-position: inside;
  }
}

// ** PARAGRAPHS
p {
  @extend %m-p-reset;
  margin: 10px 0;
}

// ** TEXT STYLING DRUPAL STYLE
.field-type-text-with-summary,
.field-type-text-long,
.views-field-body {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
  }
  p {
    margin-top: 0;
  }
  small {
    // Add custom styling
  }
  .submit,
  .button {
    @include button;
  }
}

// ** HR
hr {
  width: 100%;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid grey;
  margin: 20px 0;
  padding: 0;
}

// ** BLOCKQUOTE
blockquote {
  margin: 0;
  padding: 20px;
  background-color: lighten($black, 90%);
  width: 100%;
  border-left: 5px solid lighten($black, 80%);
  &:before {
    color: lighten($black, 80%);
    content: '';
    font-size: 50px;
    line-height: 0.1em;
    margin-right: 10px;
    vertical-align: -20px;
  }
}

// ** PREFORMATTED TEXT
pre {
  margin: 0;
}

// ** FLUID IFRAMES
.fluid-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  width: 100%;
  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}

// ** PAGER
ul.pager {
  text-align: center;
  @extend %m-p-reset;
  font-size: 0;
  li {
    @extend %m-p-reset;
    display: none;
    border: 1px solid $border-color;
    border-right: 0;
    font-size: 0;
    vertical-align: top;
    a {
      padding: 5px 10px;
      display: block;
      color: lighten($text-grey, 50%);
      text-transform: uppercase;
      @include font-size(12);
      &:hover {
        background-color: $border-color;
        color: $white;
      }
    }
    &.ellipsis,
    &.current {
      border: 1px solid $border-color;
      color: $text-grey;
      @include font-size(12);
      padding: 5px 10px;
      border-right: 0;
    }
    &.ellipsis {
      color: lighten($text-grey, 50%);
    }
    &.current {
      display: inline-block;
    }
    &.next,
    &.last,
    &.first,
    &.previous {
      display: inline-block;
      a {
        line-height: calc(12px * 1.5);
      }
    }
    &.next {
      a {
        font-size: 0;
        @include font-awesome($angle-right, lighten($text-grey, 50%), 12px, after);
      }
    }
    &.last {
      border-right: 1px solid $border-color;
      a {
        font-size: 0;
        @include font-awesome($angle-double-right, lighten($text-grey, 50%), 12px, after);
      }
    }
    &.previous {
      a {
        font-size: 0;
        @include font-awesome($angle-left, lighten($text-grey, 50%), 12px, after);
      }
    }
    &.first {
      a {
        font-size: 0;
        @include font-awesome($angle-double-left, lighten($text-grey, 50%), 12px, after);
      }
    }
  }
  @include break1 {
    li {
      display: inline-block;
      &.next,
      &.last,
      &.first,
      &.previous {
        a {
          @include font-size(12);
          &:after {
            display: none;
          }
        }
      }
    }
  }
}

.form-required {
  label {
    &:after {
      content: ' *';
      color: $red;
    }
  }
}

.exbtw {
  clear: both;
  font-size: 12px;
  color: $mediumgray;
  padding: 10px 0;
  margin-top: 15px;
}

.clearfix {
  clear: both;  
}

//alle prijzen zijn ex 21% btw block
.bean-18 {
  clear: both;  
}

//jobs outro
.bean-22 {
  margin-top: 15px;
}

//leaflet block
.bean-8 {
  position: relative;
  z-index: 0;
}

img.robot {
  width: 40% !important;
  max-width: 400px;
  height: auto !important;
  float:left;
  margin: 0 20px 10px 0;
}

/* *** MASQUERADE *** */
form#masquerade-block-1 {
  background: $bittersweet;
  padding: 20px;
  margin: 10px 0;
  input[type=submit] {
    margin: 10px 0 0;
  }
}

#callus {
  z-index: 9999999;
  bottom: 5px;
  right: 5px;
  position: fixed;
}