.cart-empty-page {
  padding: 20px;
  font-size: 16px;
  background: $lightestgray;
  border:1px solid $lightergray;
  margin: 0 0 20px;
  clear: both;
}
.view-commerce-cart-form {
  margin: 10px 0 30px;
  display: inline-block;
  clear: both;
  .view-header {
    .views-row {
      border-bottom: 1px solid $lightergray;
      padding: 10px 0;
      margin-bottom: 15px;
      background: $lightestgray;
      font-weight: 600;
      @include clearfix;
      .views-field-nothing {
        border: 1px solid $lightestgray;
      }
    }  
  }
  .views-row {
    border-bottom: 1px solid $lightergray;
    padding: 0 0 10px;
    margin-bottom: 10px;
    @include clearfix;
    
    .views-field-line-item-title {
      @include span-columns(4);
      padding: 10px;
    }
    .views-field-nothing {
      @include span-columns(4);
      border: 1px solid $white;
      padding: 10px 0;
    }
    .views-field-commerce-unit-price,
    .views-field-commerce-price {
      @include span-columns(2);
      padding: 10px 0;
    }
    .views-field-edit-quantity {
      @include span-columns(1);
    }
    .views-field-edit-delete {
      @include span-columns(1);
      input.delete-line-item {
        width: 20px;
        height: 20px;
        background: url('../images/cross.png') top center no-repeat;
        text-indent: -9999999px;
        margin:12px 0 0;
      }
    }
  }
  .commerce-line-item-actions {
    margin: 20px 0 0;
    //#edit-submit {
    //  display: none;
    //}
    #edit-checkout {
      margin: 0 0 0 10px;
      padding: 10px 30px;
    }
  }
}

.view-commerce-cart-summary {
  margin: 30px 0;
  .view-header {
    .views-row {
      border-bottom: 1px solid $lightergray;
      margin-bottom: 15px;
      background: $lightestgray;
      font-weight: 600;
      @include clearfix;
      .views-field-nothing {
        border: 1px solid $lightestgray;
      }
    }
  }
  .views-row {
    border-bottom: 1px solid $lightergray;
    padding: 10px 0 15px;
    margin-bottom: 20px;
    @include clearfix;

    .views-field-line-item-title {
      @include span-columns(5);
      padding-left: 10px;
    }
    .views-field-nothing {
      @include span-columns(4);
      border: 1px solid $white;
    }
    .views-field-commerce-unit-price,
    .views-field-commerce-price {
      @include span-columns(2);
    }
    .views-field-quantity {
      @include span-columns(1);
    }
  }
}

#commerce-checkout-form-checkout,
#commerce-checkout-form-review {
  legend {
    font-size: 20px;
    font-weight: normal;
    color: $wlx-main;
  }
  fieldset {
    margin: 20px 0;
    &.customer_profile_administratie,
    &.customer-profile-administratie,
    &.customer_profile_billing,
    &.customer-profile-billing,
    &.customer_profile_technische,
    &.customer-profile-technische
    &.commerce_payment,
    &.account,
    &.commerce_payment,
    &.customer-profile-eu-vat-rc {
      background: $lightestgray;
      label {
        font-size: 16px;
        color: $lightgray;
        margin: 10px 0 10px 10px;
      }
      .field-name-field-particulier-zakelijk {
        display: none;
      }
    }
    
    &.checkout-buttons {
      border: 0;
      text-align: right;
      padding: 0;
      #edit-continue,
      #edit-back {
        padding: 10px 30px;
        float: right;
        margin: 0 0 0 10px;
        background: $wlx-main;
        color: $white;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.checkout_review {
  fieldset {
    &.customer-profile-billing {
      .field-name-field-particulier-zakelijk {
        display: none;
      }
    }
  }
}

fieldset.commerce_coupon {
  background: $lightestgray;
  .form-item-commerce-coupon-coupon-code label,
  small {
    display: none;
  }
  input#edit-commerce-coupon-coupon-add {
    margin: 10px 0;
  }
}

#commerce-checkout-form-review {
  .account {
    label {
      &:after {
        content: ':';
      }
    }  
  }
  .field-name-field-copy-address {
    display: none;
  }
  .field-name-commerce-customer-address {
    padding: 10px 0 0 10px;
  }
  .field-name-field-btw-nummer {
    label {
      display: none;
    }
    margin: 0 0 0 10px;
  }
}

.lrg {
  .block_login,
  .block_register,
  .block_guest {
    @include span-columns(12);
    @include omega(1n);
    min-height: auto;
    @include break_tb {
      @include omega-reset(1n);
      @include span-columns(4);
      @include omega(3n);
      min-height: 450px;
    }
    background: $lightestgray;
    border: 1px solid $lightergray;
    padding: 10px 20px;
    margin-bottom: 20px;
  }
  .block_login {
    #user-login {
      background: none;
      text-align: left;
      width: 100%;
      padding: 20px;
      margin: 20px 0 0;
      input[type='text'],
      input[type='password'] {
        border: 1px solid $lightergray;
        width: 100%;
      }
      * {
        width: auto;
      }
    }
  }
  .block_register {
    #user-register-form {
      margin: 20px 0;
      input[type='text'],
      input[type='password'] {
        width: 100%;
        margin: 0 0 10px;
      }
      .confirm-parent,
      .password-parent {
        width: 100%;
      }
      .form-item {
        small {
          display: none;
        }
      }
      label,
      .password-strength,
      .password-suggestions {
        display: none !important;
      }
      .password-confirm {
        display: none;
      }
      #edit-pass-pass2 {
        display: block;
      }
      fieldset {
        border: 0;
        padding: 0;
      }
    }
  }
  .block_guest {
    a.edit-continue-guest {
      display: inline-block;
      padding: 10px 20px;
      background: $wlx-main;
      color: $white;
      margin: 10px 0;
      text-transform: uppercase;
      text-decoration: none;
      &:hover {
        background: lighten($wlx-main, 10%);
      }
    }
  }
}

span.activatiekost_,
span.activatiekost_0 {
  display: none;  
}

/* only show field in specific row */
span.line-item-type-nameserver,
span.line-item-type-extentie {
  display: none;
}

span.txt_qty {
  display: none;
}
.views-row.Domeinnaam {
  span.line-item-type-nameserver,
  span.line-item-type-extentie {
    display: block;
  }
  .views-field-edit-quantity {
    input {
      display: none;
    }
    span.txt_qty {
      display: block;
      padding: 10px;
    }
  }
}

/* *** CHECKOUT PROGRESS BAR *** */
ol.commerce-checkout-progress {
  padding: 0;
  width: 100%;
  background: $lightestgray;
  li {
    width: 25% !important;
    display: block;
    text-align: center;
    color: $white;
    background: $wlx-main;
    padding: 15px 5px;
    &.active {
      background: lighten($wlx-main, 10%);
    }
  }
}

.cta-hosting-block,
.cta-ssl-block,
.cta-domeinnaam-block,
.cta-email-block {
  color: $white;
  min-height: 80px;
  @include span-columns(12);
  @include omega(1n);
  text-align: center;
  font-size: 17px;
  margin: 0 0 5px;
  a {
    text-decoration: none;
    padding: 25px 15px 15px;
  }
  @include break1 {
    @include omega-reset(1n);
    @include span-columns(6);
    @include omega(2n);
    font-size: 18px;
    a {
      padding: 20px 13px;
      text-decoration: none;
    }
  }
  @include break2 {
    @include omega-reset(2n);
    @include span-columns(3);
    @include omega(4n);
    font-size: 23px;
    a {
      padding: 20px 13px;
      text-decoration: none;
    }
  }
  p {
    margin: 0;
  }
}

.cta-domeinnaam-block {
  float: left;
  background: $koamaru;
  a.cta-domeinnaam {
    text-decoration: none !important;
    color: $white;
    display: block;
    width: 100%;
    height: 100%;
    &:hover {
      color: lighten($koamaru, 50%);
    }
  }
}

.cta-hosting-block {
  float: left;
  background: $wlx-main;
  a.cta-hosting {
    text-decoration: none !important;
    color: $white;
    display: block;
    width: 100%;
    height: 100%;
    &:hover {
      color: lighten($wlx-main, 50%);
    }
  }
}

.cta-email-block {
  float: left;
  background: $koamaru;
  margin-bottom: 10px;
  a.cta-email {
    text-decoration: none !important;
    color: $white;
    display: block;
    width: 100%;
    height: 100%;
    &:hover {
      color: lighten($koamaru, 50%);
    }
  }
}

.cta-ssl-block {
  float: left;
  background: $wlx-main;
  a.cta-ssl {
    text-decoration: none !important;
    color: $white;
    display: block;
    width: 100%;
    height: 100%;
    &:hover {
      color: lighten($wlx-main, 50%);
    }
  }
}

.customer_profile_eu_vat_rc {
  .field-name-field-particulier-zakelijk {
    margin: 10px 0 20px;
    label {
      display: none;
    }
    .form-type-radio {
      display: inline-block;
      margin-right: 20px;
      label {
        display: inline-block;
      }
    }
  }
}

fieldset.commerce_coupon,
fieldset.commerce-coupon {
  .views-field-discount-value-display {
    display: none;
  }
}

fieldset {
  legend {
    a {
      color: $wlx-main;
      text-decoration: none !important;
    }
  }
}

body.page-cart {
  .commerce_coupon {
    background: $lightestgray;
    padding: 10px 20px 20px;
    margin: 10px 0;
    label {
      color: $wlx-main;
      font-size: 18px;
      font-weight: lighter;
      margin: 10px 0 3px;
    }
    small {
      display: none;
    }
    input[type=text] {
      margin: 10px 0;
    }
    .view-order-coupon-list {
      ul {
        list-style-image: none;
        li {
          list-style: none;
        }
      }
      .views-field-discount-value-display {
        display: none;
      }
    }
  }
}